import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsFillEyeFill, BsPerson } from "react-icons/bs";
import { FaPlane, FaTruck } from "react-icons/fa";
import { TbShip } from "react-icons/tb";
import { ReactTyped } from "react-typed";
import { MdOutlineLock } from "react-icons/md";

import {
  errorToast,
  successToast,
} from "../../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../../api/api";
import { PrimaryContext } from "../../../components/MainContext/MainContext";
import ForgetPassword from "./ForgetPassword";

import planeImg from "../../../images/constants/plane.png";
import shipImg from "../../../images/constants/ship.png";
import Register from "./Register";

const FirstSection = () => {
  //translations
  const { t } = useTranslation();
  //navigation
  const navigate = useNavigate();
  //register credentials
  const [credentials, setCredentials] = useState({});
  //context functions
  const { setToken, setUser, setIsAuthorized, isAuthorized, user, language } =
    useContext(PrimaryContext);
  //all fields are filled
  const [allFilled, setAllFilled] = useState(false);
  //show forget password popup
  const [toggleForget, setToggleForget] = useState(false);
  //password input type
  const [passwordInputType, setPasswordInputType] = useState("password");
  //toggle registration
  const [toggleRegistration, setToggleRegistration] = useState(false);

  //credentials handler
  const handleCredentials = (value, inputName) => {
    setCredentials((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleLoginApi = async () => {
    try {
      const res = await axiosClient.post(`/login`, credentials);
      if (res) {
        setToken(res.data.token);
        setUser(res.data.user);
        setIsAuthorized(true);
        successToast(t("welcome"));
        navigate("/personal-cabinet/my-ballance");
      }
    } catch (error) {
      errorToast(t("password_email_incorrect"));
    }
  };

  useEffect(() => {
    if (
      credentials.email !== null &&
      credentials.email !== "" &&
      credentials.password !== null &&
      credentials.password
    ) {
      setAllFilled(true);
    } else {
      setAllFilled(false);
    }
  }, [credentials]);

  //handle logout
  const handleLogout = () => {
    axiosClient.get("/logout").then((res) => {
      localStorage.removeItem("MGL_TOKEN");
      setToken("");
      setUser({});
      setIsAuthorized(false);
      navigate("/");
    });
  };

  return (
    <div className="w-full afMd:h-[70vh] flex md:flex-col md:py-10">
      <div
        className="afMd:w-6/12 h-full flex justify-center items-center relative
        md:mx-auto"
      >
        <div className="px-10 md:px-6 relative z-20 md:w-full">
          <h1 className=" font-bold text-[36px] md:text-[28px] sm:text-[24px] md:text-center">
            {t("quotePartOne")}
          </h1>
          <h1 className=" font-bold text-[36px] md:text-[28px] sm:text-[24px] md:text-center pt-1">
            {t("quotePartTwo")}{" "}
            <ReactTyped
              strings={[t("reliable"), t("easy"), t("fast")]}
              typeSpeed={100}
              backSpeed={90}
              loop
              cursorChar=""
            >
              <span
                className="text-white bg-teal_600 px-[14px] py-[2px] font-bold text-[36px]
            md:text-[26px] sm:text-[22px]"
              ></span>
            </ReactTyped>
          </h1>
          <h1 className=" font-bold text-[36px] md:text-[28px] sm:text-[24px] md:text-center pt-1">
            {t("quotePartThree")}
          </h1>
          <div className="mt-10 md:mx-auto sm:mt-5">
            {/* <div className="flex items-center gap-2">
              <div
                className="text-white bg-teal_600 flex items-center justify-center
                            p-2 h-10 w-10 rounded-full sm:h-8 sm:w-8"
              >
                <FaTruck className="text-4xl" />
              </div>
              <h6 className="text-xl sm:text-lg">
                {" "}
                {t("land")} - 2$ ({t("turkey")})
              </h6>
            </div> */}
            <div className="flex items-center gap-2 mt-3">
              <div
                className="text-white bg-teal_600 flex items-center justify-center
                            p-2 h-10 w-10 rounded-full sm:h-8 sm:w-8"
              >
                <FaTruck className="text-4xl" />
              </div>
              <div>
                <h6 className="text-xl sm:text-lg">
                  {t("land")} - 4.5$ ({t("china")})
                </h6>
                <h5 className="text-sm text-teal_600">
                  {t("minimum_package_weight")} - 100{t("g")}
                </h5>
              </div>
            </div>
            <div className="flex items-center gap-2  mt-3">
              <div
                className="text-white bg-teal_600 flex items-center justify-center
                            p-2 h-10 w-10 rounded-full sm:h-8 sm:w-8"
              >
                <FaPlane className="text-4xl" />
              </div>
              <h6 className="text-xl sm:text-lg">
                {t("air_freight")} - {t("airPrice")} ({t("china")})
              </h6>
            </div>
            <div className="flex items-center gap-2 mt-3">
              <div
                className="text-white bg-teal_600 flex items-center justify-center
                            px-2 h-10 w-10 rounded-full sm:h-8 sm:w-8"
              >
                <TbShip className="text-4xl" />
              </div>
              <div>
                <h6 className="text-xl sm:text-lg">
                  {t("sea_freight")} - 3.0$ ({t("china")})
                </h6>
                <h5 className="text-sm text-teal_600">
                  {t("minimum_package_weight")} - 100{t("g")}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="afMd:w-6/12 flex justify-center relative h-full afMd:bg-[#FAFAFA]
      md:px-6 md:mt-10"
      >
        <img
          src={planeImg}
          alt="#"
          className="absolute top-[3%] left-[-10%] w-60 z-10 md:hidden"
        />
        <img
          src={shipImg}
          alt="#"
          className="absolute bottom-0 right-0 w-60 z-10 md:hidden"
        />
        <div className="flex flex-col items-center w-8/12 relative z-20 md:w-full justify-center">
          <h6 className="text-2xl md:text-xl font-bold text-center">
            {t("log_in")}
          </h6>

          {!isAuthorized ? (
            <>
              <div className="border border-solid border-black rounded-[30px] flex items-center gap-2 p-4 w-full mt-5">
                <BsPerson className="shrink-0 text-[22px]" />
                <input
                  type="text"
                  placeholder={t("email_address")}
                  className="w-full border-none outline-none bg-transparent"
                  onChange={(e) => {
                    handleCredentials(e.target.value, "email");
                  }}
                />
              </div>

              <div className="border border-solid border-black rounded-[30px] flex items-center gap-2 p-4 w-full mt-5">
                <MdOutlineLock className="shrink-0 text-[22px]" />
                <input
                  type={passwordInputType}
                  placeholder={t("password")}
                  className="w-full border-none outline-none bg-transparent focus:bg-transparent"
                  onChange={(e) => {
                    handleCredentials(e.target.value, "password");
                  }}
                />
                <BsFillEyeFill
                  className="shrink-0 text-[22px] cursor-pointer"
                  onClick={() => {
                    setPasswordInputType((prev) =>
                      prev === "text" ? "password" : "text"
                    );
                  }}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center gap-5 mt-5 w-full ">
              <Link
                to={"/personal-cabinet/my-ballance"}
                className="inline-block w-full"
              >
                <button
                  className="cursor-pointer bg-teal_600 text-center text-base text-white 
                            p-4 rounded-[30px] w-full font-bold"
                  onClick={() => {}}
                >
                  {user.company_id === null ? (
                    <>
                      {language === "ge" &&
                        user &&
                        `${user.fname} ${user.lname}`}
                      {language === "en" &&
                        user &&
                        `${user.fname_eng} ${user.lname_eng}`}
                    </>
                  ) : (
                    <>
                      {language === "ge" && user && `${user.cname}`}
                      {language === "en" && user && `${user.cname_eng}`}
                    </>
                  )}
                </button>
              </Link>
              <button
                onClick={handleLogout}
                className="cursor-pointer bg-[#D6D6D6] text-center text-base 
                            p-4 rounded-[30px] w-full font-bold"
              >
                {t("logout")}
              </button>
            </div>
          )}
          {!isAuthorized && (
            <>
              <div className="flex justify-end mt-5 w-full">
                <p className="not-italic w-auto text-sm sm:text-xs  font-bold">
                  {t("forgot_password")}{" "}
                  <span
                    className="text-[#009D68] cursor-pointer"
                    onClick={() => {
                      setToggleForget(true);
                    }}
                  >
                    {t("recover_here")}
                  </span>
                </p>
              </div>

              <div className="flex items-center gap-5 mt-5 w-full">
                <button
                  className="cursor-pointer bg-teal_600 text-center text-base text-white 
                            p-4 rounded-[30px] w-4/12 font-bold"
                  onClick={handleLoginApi}
                >
                  {t("enter")}
                </button>
                <button
                  onClick={() => {
                    setToggleRegistration((prev) => !prev);
                  }}
                  className="cursor-pointer bg-[#D6D6D6] text-center text-base 
                            p-4 rounded-[30px] w-8/12 font-bold"
                >
                  {t("register")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {toggleForget && <ForgetPassword closePopupSetter={setToggleForget} />}
      {toggleRegistration && (
        <Register setToggleRegistration={setToggleRegistration} />
      )}
    </div>
  );
};

export default FirstSection;
