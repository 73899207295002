import Line from "../../components/InputComponents/Line";
import TextInput from "../../components/InputComponents/TextInput";
import TextArea from "../../components/InputComponents/TextArea";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../api/api";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { BiChevronLeft } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import FileInput from "../../components/InputComponents/FileInput";

const Assisant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const form = event.target;
      const formData = new FormData(form);

      formData.append("type", 1);

      const res = await axiosClient.post(`/message`, formData);

      if (res) {
        successToast("შეტყობინება გაიგზავნა!");
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა!");
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("assistance")}</h2>
      </div>
      <div
        className="shadow-bs1 rounded-[30px] mt-5 bg-white
            flex flex-col gap-2"
      >
        <div className="p-4 border-b border-gray-200">
          <div
            className="flex gap-2 items-center justify-start cursor-pointer "
            onClick={() => navigate(-1)}
          >
            <BiChevronLeft className="h-6 w-6 text-teal_600" />
            <p className="font-semibold text-base md:text-sm">{t("go_back")}</p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full"
        >
          <div className="p-4 flex flex-col gap-5">
            <TextInput name={t("subject")} inputName={"subject"} />
            <TextArea name={t("message")} inputName={"message"} />
            <div className="grid grid-cols-2 sm:grid-cols-1">
              <FileInput name={t("attach_file")} inputName={"img"} />
            </div>
            <button
              className="cursor-pointer font-normal leading-[normal] text-md text-center w-32
                            rounded-[11px] p-[10px] bg-teal_600_b2 border-2 border-solid border-teal_600 text-white_A700"
              type="submit"
            >
              {t("send")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Assisant;
