import Line from "../../components/InputComponents/Line";
import TextInput from "../../components/InputComponents/TextInput";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../api/api";
//icons
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const AddTrustedPerson = ({ setShow, data, setUpdatePacks }) => {
  const { t } = useTranslation();

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const id = formData.get("another_person_id");

    if (id) {
      axiosClient
        .post(`/updateWithItems/${data.id}`, formData)
        .then((res) => {
          setUpdatePacks(res);
          successToast("successful");
        })
        .catch(() => {
          errorToast("error");
        });
    } else {
      errorToast("გთხოვთ მიუთითეთ პირადი ნომერი");
    }
  }

  return (
    <div
      className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50"
    >
      <div
        className="bg-white flex flex-col w-[40%] md:w-[80%] p-7 sm:px-5
            border border-gray-300 shadow-sm rounded-[30px] relative"
      >
        <BsX
          onClick={() => {
            setShow(false);
          }}
          className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
        />
        <div className="flex flex-col gap-2 items-center justify-center w-full">
          <Line className="bg-teal-600 h-0.5 w-20" />
          <h2 className="text-center font-bold w-auto text-xl">
            {t("trusted_person")}
          </h2>
        </div>
        <Line className=" bg-gray_300 h-0.5 w-full my-5" />
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full overflow-auto scrollbar"
        >
          <div className="flex flex-col gap-2 w-full+">
            <p className="font-bold text-left text-teal_600 w-auto text-sm sm:text-xs">
              {t("tracking_number")} #: {data.tracking_number}
            </p>
            <TextInput
              name={t("name") + " " + t("surname")}
              inputName={"another_person"}
              value={data.another_person}
            />
            <TextInput
              name={t("personal_id")}
              inputName={"another_person_id"}
              value={data.another_person_id}
            />
            <div className="flex mt-4">
              <button
                className="bg-teal_600_b2 border-2 border-solid border-teal_600 flex items-center justify-center px-[7px]
                            rounded-[12px] h-12 cursor-pointer font-bold text-left text-white text-sm sm:text-xs"
                type="submit"
              >
                {t("save_changes")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTrustedPerson;
