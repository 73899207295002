const translationEn = {
  home: "Home",
  about_us: "About Us",
  services: "Services",
  air_freight: "Air",
  land_freight: "Land",
  sea_freight: "Sea",
  prices: "Prices",
  contact: "Contact",
  log_in: "Log In",
  sign_up: "Sign Up",
  welcome: "Welcome",
  password_email_incorrect: "Password or email incorrect !",
  transportation_in_any_direction: "Transportation in any direction",
  logistics_is_simple_with_us: "Logistics is simple with us",
  email_address: "Email address",
  password: "Password",
  forgot_password: "Forgot password ?",
  recover_here: "Recover here",
  enter: "Enter",
  not_register_yet: "Not register yet ?",
  passwords_dont_match: "Passwords do not match!",
  fill_all_fields: "Fill all fields !",
  agree_terms: "For registratio fill all fields !",
  email_or_already_registerd: "Email invalid or user is already registered !",
  physical_person: "Physical person",
  legal_entity: "Legal entity",
  name: "Name",
  name_eng: "Name Eng",
  personal_id: "Personal id",
  company_id: "Company ID",
  repeat_password: "Repeat password",
  surname: "Surname",
  surname_eng: "Surname Eng",
  company_name: "Company Name",
  company_name_eng: "Company Name Eng",
  phone: "Phone",
  password_eight_character: "(8 characters or more)",
  agree_to_our: "Agree to our ",
  terms_of_use: "terms of use",
  and: "and",
  privacy_policy: "privacy policy",
  already_have_an_account: "Already have an account?",
  send: "Send",
  got_questions_or_need_more_information:
    "Got questions or need more information?",
  fill_in_your_information_and_we_will_get_back_to_you:
    "Fill in your information and we will get back to you",
  error_msg: "Something went wrong!",
  footer_text: `MGL Parcel is a new parcel delivery service of MGL Group, which is a Bulgarian-Georgian company and has been operating in the Georgian market since 2012.
    We offer delivery of parcels from China and Turkey.`,
  frequently_asked_questions: "Frequently asked questions",
  balance: "Balance",
  my_balance: "My Balance",
  balance_top_up: "Balance Top Up",
  bonus_points: "Bonus Points",
  parcels: "Parcels",
  my_parcels: "My Parcels",
  courier_service: "Courier Service",
  courier: "courier",
  addresses: "Addresses",
  flights: "Flights",
  reports: "Reports",
  transaction_history: "Transaction History",
  insurance: "Insurance",
  settings: "Settings",
  support: "Support",
  messages: "Messages",
  terms_agreement: " Terms & Agreement User policy",
  save_changes: "Save changes",
  change: "Change",
  changed: "Changed",
  go_back: "Go Back",
  new_password: "New password",
  repeat_new_password: "Confirm new password",
  new_email: "New email",
  email_changed: "Your email was changed",
  new_phone: "New phone",
  message: "Message",
  see_more: "See more",
  my_messages: "My messages",
  received_messages: "Received Messages",
  create_new_messages: "Create a new message",
  type_word_here: "Type the word here...",
  message_details: "Messege Details",
  subject: "Subject",
  assistance: "Assistance",
  transaction_history: "Transaction history",
  date: "Date",
  amount: "Amount",
  status: "Status",
  bank: "Bank",
  all_fights: "All Flights",
  land: "Land",
  air: "Air",
  sea: "Sea",
  select_country: "Select a Country",
  tatus: "Status",
  flight_number: "Shipment Number",
  estimated_arrival_date: "Estimated Arrival Date",
  arrival_date: "Arrival Date",
  address: "Address",
  town_city: "Town / City",
  state_province: "State / Province",
  country: "Country",
  post_code: "Post code (ZIP)",
  tracking_number: "Tracking Number",
  received: "Received",
  sent: "Sent",
  arrived: "Arrived",
  takeout: "Takeout",
  weight: " Weight",
  price: "Price",
  pay: "Pay",
  paid: "Paid",
  details: "Details",
  declare: "Declare",
  shipment: "Shipment",
  declaration_details: "Declaration Details",
  deliver_address: "Delivery Address",
  security: "Security",
  description: "Parcel description",
  website: "Web Page",
  currency: "Currency",
  upload_invoice: "Upload Invoice",
  addon_security: "Addon Security",
  save: "Save",
  invoice_details: "Invoice details",
  user_information: "User information",
  transportation_details: "Transportation details",
  transportation_price: "Transportation price",
  insurance_price: "Insurance price",
  total_value: "Total value",
  bank_information: "Bank information",
  account: "Account",
  type_amount_here: " Type Amount Here",
  choose_method: "Please choose desired payment method",
  payment: "Payment",
  amount: "Amount",
  top_up_my_ballance: "Top up my balance",
  view_bonus_points: "View Bonus Points",
  action: "Action",
  charging_amount_from_ballance: "Charging Amount from Balance",
  kg: "KG",
  g: "G",
  pay_with_points: "Pay with points",
  warehouse: "Warehouse",
  trustee: "Trustee",
  office: "Office",
  trcaution: `The authorized person can receive the parcel both at the courier address and at the specified office.`,
  transportation: "Transportation",
  about_us_banner_text: `Experience seamless parcel logistics with MGL PARCEL! For over a decade, we have been dedicated to providing reliable transportation services for your parcels originating from China and Turkey,`,
  about_us_banner_quote: "Parcels are simple with us.",
  learn_more_about_us: "Learn More About Us",
  ten_year_eperiance: "10 YEARS OF EXPERIENCE",
  useful_information: "Useful Information",
  customs_regulations: "Customs regulations",
  client: "Client",
  street: "Street",
  terms_and_conditions: "Terms and conditions",
  minimum_package_weight: "Minimum package weight",
  track_parcels: "Track your package here",
  track_parcels_quote:
    "Find out the status of the package according to its tracking number",
  parcel_status: "Parcel status - ",
  data_not_found: "Data not found",
  send_new_password: "Send new password",
  password_sent: "Password sent on email!",
  email_invalid: "Email is not registered!",
  do_not_have_insuranced: "You do not have an insured package",
  only_georgian: "Write text only Georgian",
  unclear_parcels: "Unclear Parcels",
  unlcear_parcels_page_txt:
    "Find your package and send a message with the appropriate photo material.",
  sent_at: "Sent Date",
  terminals_at:
    "Date of commencement of terminal proceedings (needs 2-3 working days)",
  customs_at:
    "Date of initiation of customs procedures (needs 2-3 working days)",
  redistribution_at: "Distribution date to branches (needs 2-3 working days)",
  last_updated: "Last updated",
  pay_all: "Pay All",

  inner_about_us1: `Discover MGL Parcel - your trusted logistics partner with extensive experience and a strong presence across Europe, Georgia, and China. With a network of offices and warehouses strategically located in key locations, we offer seamless transportation services for your parcels, ensuring reliable and efficient delivery.`,
  inner_about_us2: `At MGL Parcel, we specialize in handling shipments from Turkey and China, providing you with a hassle-free solution to transport your parcels. Our expertise and established relationships enable us to navigate the complexities of international logistics, ensuring your packages reach their destinations safely and on time.`,
  inner_about_us3: `With a customer-centric approach, we prioritize your satisfaction by offering tailored solutions to meet your specific requirements. Whether you're an individual sending personal packages or a business requiring comprehensive logistics support, we have the capabilities and resources to fulfill your needs.`,
  inner_about_us4: `Experience the convenience and peace of mind that comes with partnering with MGL Parcel. Let us handle the transportation of your parcels from Turkey and China, while you focus on your core business. Trust us to deliver your goods with care, efficiency, and expertise. Contact us today to learn more about our services and how we can assist you in your logistics needs.`,

  our_services: "Our Service",
  land_transportation: "Land transportation",
  kg_constraint: "The minimum weight of the package is 100 grams",
  land_serv1: "Italy – 3  EUR 1 KG /  100 EUR - 1 CBM",
  land_serv2: "Turkey – 2 USD -1 KG",
  land_serv3: "China – 5.5 USD -1 KG",
  air_transportation: "Air transportation",
  air_serv1: "China – from 6.85 USD – 1 KG",
  air_serv2: "Special Offer for  cargo  over 45 KG",
  sea_transportation: "Sea transportation",
  sea_serv1: "China – 3.5 USD – from 1 KG to 100KG / from  100KG - 3 USD",

  customs_rules_and_laws: "Customs rules and laws",
  customs_rules_and_laws_text:
    "As of May 14, 2022, Resolution No. 201 of the Government of Georgia was changed. According to the latest amendment, the Government of Georgia imposes a customs service tax on the goods to be cleared, along with the customs clearance amount.",
  customs_rules_and_laws_text1:
    "• 20 GEL will be added to the customs clearance amount for goods worth from 300 to 3,000 GEL;",
  customs_rules_and_laws_text2:
    "• 100 GEL will be added to the customs clearance amount for goods worth 3,000 to 10,000 GEL;",
  customs_rules_and_laws_text3:
    "The said amount will be paid to the account of the Treasury of Georgia (see account: 707017021), before the declaration is printed and the customs clearance fee is paid.",
  customs_rules_and_laws1:
    "In what cases is the parcel subject to customs clearance?",
  customs_rules_and_laws1_text:
    "• According to the legislation of Georgia, goods worth more than 300 GEL, weighing more than 30 kg or uniform goods are subject to customs clearance.",
  customs_rules_and_laws1_text1:
    "• Customs duty is 18% of the total cost of products and transportation.",
  customs_rules_and_laws2: "Why should you declare the item before arrival?",
  customs_rules_and_laws2_text:
    "• If the parcel is not declared by the time of its arrival, it will be delayed at customs for 1 working week.",
  customs_rules_and_laws2_text1: `• If you do not declare the parcel within the mentioned period, the parcel will be automatically handed over to the state for further disposal.
    Note that if several parcels cross the border in the name of the customer, and only a part is undeclared, customs will stop the customer's declared parcels along with the undeclared parcels.
    After 1 week, according to the law, all parcels together are transferred to the state.`,
  customs_rules_and_laws3:
    "What documentation is required to clear the parcel?",
  customs_rules_and_laws3_text:
    "• In the event that the parcel is subject to customs clearance, the customer must upload an invoice or purchase order to our website containing the following information:",
  customs_rules_and_laws3_text1: "•  Recipient's name",
  customs_rules_and_laws3_text2: "•  Recipient's last name",
  customs_rules_and_laws3_text3: "•  Room number",
  customs_rules_and_laws3_text4: "•  Package contents",
  customs_rules_and_laws3_text5: "•  Total value of the package.",
  customs_rules_and_laws3_text6: `Note: (1) According to the new rules of the Revenue Service, fines are provided in case of wrongly indicated value by the recipient of the parcel in the declaration`,
  customs_rules_and_laws4:
    "According to the legislation of Georgia, it is prohibited to send the following items by mail:",
  customs_rules_and_laws4_text: "• real money, coins or banknotes;",
  customs_rules_and_laws4_text1:
    "• bank and credit cards and other securities;",
  customs_rules_and_laws4_text2: "• food products;",
  customs_rules_and_laws4_text3: "• Explosive substances;",
  customs_rules_and_laws4_text4: "• soil and seedlings with soil;",
  customs_rules_and_laws4_text5: "• pornographic products;",
  customs_rules_and_laws4_text6: "• live animals;",
  customs_rules_and_laws4_text7:
    "• any kind of drugs, products containing narcotic substances;",
  customs_rules_and_laws4_text8:
    "• Plants or their seeds containing narcotic or psychotropic substances;",
  customs_rules_and_laws4_text9: "• psychotropic substances;",
  customs_rules_and_laws4_text10: "• Medicines (more than 10 packs);",
  customs_rules_and_laws4_text11: "• seeds of plants (more than 25 grams);",
  customs_rules_and_laws5:
    "List of prohibited items by country and destination:",
  customs_rules_and_laws5_text: "• Turkey land - medicines.",
  customs_rules_and_laws5_text1: `• China Air - Gun, target, gun parts, etc., magnet, candle, lighter (including electric lighter), powder, pressed powder, toothpaste, eye shadow, knife, scissors and similar sharp objects, toy gun, electronic cigarette , power bank, battery, liquids, paint, felt-tip pen, plants, 
    Cargo for medical purposes, items (it is allowed to bring an item only if it comes with the item, for example - a mobile phone) and cosmetics such as: manicure, acetone, creams and others. Products with explosive and flammable packaging. Among them: spray, pulverizer, deodorizer, aerosol and perfume.`,
  customs_rules_and_laws5_text2:
    "• China Marine - plants, products with explosive and flammable packaging. Weapons, targets, weapon parts, etc.",

  panel_insurance_text:
    "MGL PARCEL offers its customers to insure parcels before sending them to Georgia. and thereby provide full compensation for loss in case of loss of the parcel or damage to it during transportation.",
  panel_insurance_text1:
    "The registered parcel can be insured after receiving it before sending the parcel to Georgia. 10% of the value of the package will be added to the cost of transportation in case of insurance.",

  თურქეთი: "Turkey",
  ჩინეთი: "China",

  code_sent: "Code sent to email",
  email_registerd_or_error:
    "The specified email is already registered or another error has occurred. Please try again.",
  invalid_code_or_error:
    "The specified code is invalid or another type of error has occurred. Please try again.",
  email_valid_procc:
    "Please go through the email validation process before continuing",
  required: "Required",

  not_started: "Not Started",
  sent: "Sent",
  arrived: "Arrived",
  attach_file: "Attach File",
  attached_file: "Attached File",
  denied_items: "Denied Items",

  georgian: "GEO",
  personal_parcel: "Personal Parcel",
  parcel_amount: "Item Amount",
  trusted_person: "Trusted Person",

  batumi_poti: "Batumi / Poti",
  working_hours: "Working hours",
  tbilisi: "Tbilisi",
  monday_friday: "Monday - Friday:",
  saturday: "Saturday:",

  repack: "Repack",
  repacked: "Repacked",

  calculate: "Calculate",
  vol_calculator: "Volumetric weight calculator",
  repack_err:
    "Unable to repack the package because the price of the package is calculated based on the actual weight",
  onlineShop: "Online Shop",

  declarationPrices: "Declaration",
  register: "register",
  instruction: "Instructions",
  calculator: "calculator",
  information: "information",
  logout: "logout",

  quotePartOne: "The most reliable way to",
  quotePartTwo: "order parcels",
  quotePartThree: "from",
  bannerTextOne: "Retail Direction",
  bannerTextTwo: "Corporate Direction",
  yearsWithYou: "year with you",
  flight: "Flight",
  volume: "Volume",
  workingDay: "working day",
  calculatorsTitle: "Definition of volumetric weight",
  calculatorsTextOne: `Volumetric weight is a special unit of measurement that is used
  To determine the density of a specific item. This measurement is important for international aviation
  During the execution of shipments. The formula for calculating volumetric weight is: length
  (cm) x width (cm) x height (cm) /6000 = volumetric weight (kg).`,
  instructionsTitle: "How to subscribe?",
  instructionsText: `We offer you video instructions on how to subscribe
  from Chinese online stores and how to add our shipping address,
   on various trading platforms. Also how to declare by us
   registered parcel`,
  seeAll: "see all",
  instructions: "Instructions",
  noInstructions: "instructions not found",
  logout: "log out",
  height: "height",
  width: "width",
  length: "length",
  giftText: "For every 10 kilograms, 1 kilogram as a gift !",
  easy: "easily",
  reliable: "reliably",
  codes: "codes",
  else: "else",
  empty: "No records found !",
  registration_successful: "Registration Successful",
  userCabinet: "user cabinet",
  fast: "fast",
  china: "China",
  turkey: "Turkey",
  uploadInvoice: "Invoice Required",
  airPrice: "from 6.85$",

  more_than_250: "The price of the package exceeds 250 GEL",
  more_than_30: "The weight of the package exceeds 30 kilos",
  more_than_4: "The amount of items is greater than 4",

  blogs: "Blogs",
  blogs_not_found: "Blogs not found",

  real_weight: "Real",
  volume_weight: "Volumetric",
  promoCode: "Promo code",
  checkPromoCode: "Check promo code",
  promoCodeDoNotExits: "This promo code does not exist",
  rateUs: "Rate us",
  leaveReview: "Leave a review",
};

export default translationEn;
