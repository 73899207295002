import { useState, useEffect, useContext } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import BlogCardAdmin from "./BlogCardAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  MultipleFileInput,
  MultyLangualTextArea,
  MultyLangualTextInput,
  TextInput,
} from "../../inputComponents/inputcomp";
import { FileInput } from "../../inputComponents/inputcomp";

const BlogsAdmin = () => {
  const [addNewToggler, setAddNewToggler] = useState(false);
  const [newData, setNewData] = useState({});
  const [blogData, setBlogData] = useState([]);

  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  const handleAddNewData = (value, inputName) => {
    setNewData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleFetchData = () => {
    axiosAdmin
      .get(`/blog`)
      .then(({ data }) => {
        setBlogData(data);
      })
      .catch((err) => {});
  };

  const handleDeleteData = (id) => {
    axiosAdmin
      .delete(`/blog/${id}`)
      .then((res) => {
        handleFetchData();
        successToast("ბლოგი წარმატებით წაიშალა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  const handleAddNewToDb = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("titleGe", newData.titleGe);
    formData.append("titleEn", newData.titleEn);
    formData.append("smalTextGe", newData.smalTextGe);
    formData.append("smalTextEn", newData.smalTextEn);
    formData.append("textGe", newData.textGe);
    formData.append("textEn", newData.textEn);
    formData.append("type", "blog");

    axiosAdmin
      .post(`/blog`, formData)
      .then((res) => {
        handleFetchData();
        successToast("ბლოგი წარმატებით დაემატა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
      <div className="w-full pt-10 p-5 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0"
          >
            ახალი ბლოგის დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900">
            <div className="sm:px-0">
              <form
                onSubmit={(e) => {
                  handleAddNewToDb(e);
                }}
                encType="multipart/form-data"
              >
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                  <div className="flex items-center justify-between flex-wrap gap-5">
                    <div className="flex items-center gap-5 sm:gap-3 ">
                      <div>
                        <h2
                          className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                        >
                          ახალი ბლოგის დამატება
                        </h2>
                      </div>
                    </div>
                    <div className="flex gap-2 sm:w-full">
                      <button
                        type="submit"
                        className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                      >
                        შენახვა
                      </button>
                      <button
                        onClick={() => {
                          handleAddNewToggle(false);
                        }}
                        className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <FileInput name={"მთავარი ფოტო"} inputName={"img"} />
                  <div className="mt-5">
                    <MultipleFileInput
                      name={"შიდა ფოტოები"}
                      inputName={"images"}
                    />
                  </div>
                  <MultyLangualTextInput
                    name={"სათაური"}
                    geInputName={"titleGe"}
                    enInputName={"titleEn"}
                    inputSetter={handleAddNewData}
                  />
                  <MultyLangualTextArea
                    name={"მოკლე აღწერა"}
                    geInputName={"smalTextGe"}
                    enInputName={"smalTextEn"}
                    inputSetter={handleAddNewData}
                  />
                  <MultyLangualTextArea
                    name={"ვრცელი აღწერა"}
                    geInputName={"textGe"}
                    enInputName={"textEn"}
                    inputSetter={handleAddNewData}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen p-5">
        <div className="grid grid-cols-1 gap-2">
          {blogData.map((item) => {
            return (
              <BlogCardAdmin
                key={item.id}
                item={item}
                handleDelete={handleDeleteData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogsAdmin;
