import { useEffect, useState } from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";
import axiosClient from "../api/api";

const TmpPopup = ({ setTmpPop }) => {
  const [data, setData] = useState([]);
  const [currentNotification, setCurrentNotification] = useState(0);
  const [language, setLanguage] = useState("ge");

  const handleNextNotification = () => {
    if (currentNotification < data.length - 1) {
      setCurrentNotification(currentNotification + 1);
    }
  };

  useEffect(() => {
    axiosClient
      .get("/popup")
      .then(({ data }) => {
        setData(data);
      })
      .then((err) => {});
  }, []);

  return (
    <>
      {data.length ? (
        <div
          onClick={() => {
            setTmpPop((prev) => !prev);
          }}
          className="fixed top-0 left-0 z-[60] w-screen h-screen bg-black
  bg-opacity-80 flex items-center justify-center"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white rounded-lg p-10 flex flex-col items-center justify-center gap-5 min-w-[280px]"
          >
            <div className="flex items-center justify-between w-full">
              <div
                className={`rounded-full w-12 h-12 border-2  p-1 border-teal-600`}
              >
                <FaRobot className={`w-full h-full text-teal-600`} />
              </div>
              <div className="flex items-center gap-2">
                <p
                  onClick={() => {
                    setLanguage("ge");
                  }}
                  className="text-md text-teal_600 max-w-[400px] sm:max-w-[280px] cursor-pointer mt-1"
                >
                  GE
                </p>
                <p
                  onClick={() => {
                    setLanguage("en");
                  }}
                  className="text-md text-teal_600 max-w-[400px] sm:max-w-[280px] cursor-pointer  mt-1"
                >
                  EN
                </p>
                <p
                  onClick={() => {
                    setLanguage("ru");
                  }}
                  className="text-md text-teal_600 max-w-[400px] sm:max-w-[280px] cursor-pointer  mt-1"
                >
                  RU
                </p>
                {currentNotification < data.length - 1 ? (
                  <div onClick={handleNextNotification}>
                    <BiRightArrowAlt className="text-teal_600 text-3xl cursor-pointer" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setTmpPop((prev) => !prev);
                    }}
                  >
                    <BsX className="text-teal_600 text-4xl cursor-pointer" />
                  </div>
                )}
              </div>
            </div>
            <div>
              <p className="text-sm text-gray-500 max-w-[400px] sm:max-w-[280px]">
                {data[currentNotification][language]}
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default TmpPopup;
