import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  MultyLangualTextInput,
  MultyLangualTextArea,
  FileInput,
  MultipleFileInput,
} from "../../inputComponents/inputcomp";
import logo from "../../../img/phoneLogo.png";

const InnerBlogAdmin = () => {
  const { blogId } = useParams();
  const [initialData, setInitialData] = useState({});
  const [newData, setNewData] = useState({});

  const handleNewData = (value, inputName) => {
    setNewData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleFetchOne = () => {
    axiosAdmin
      .get(`/blog/${blogId}`)
      .then(({ data }) => {
        setInitialData(data);
      })
      .catch((err) => {});
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("titleGe", newData.titleGe);
    formData.append("titleEn", newData.titleEn);
    formData.append("smalTextGe", newData.smalTextGe);
    formData.append("smalTextEn", newData.smalTextEn);
    formData.append("textGe", newData.textGe);
    formData.append("textEn", newData.textEn);

    axiosAdmin
      .post(`/blog/${blogId}`, formData)
      .then((res) => {
        handleFetchOne();
        successToast("ბლოგი წარმატებით განახლდა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  useEffect(() => {
    handleFetchOne();
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <form
        onSubmit={(e) => {
          handleUpdate(e);
        }}
        encType="multipart/form-data"
        className="sm:px-0"
      >
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-[100px] h-10">
                <img src={logo} className="w-full h-full" />
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  ბლოგის რედაქტირება
                </h2>
              </div>
            </div>
            <button
              type="submit"
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <FileInput name={"მთავარი ფოტო"} inputName={"img"} />

          <div>
            <div className="w-20 h-20">
              <img
                src={`${process.env.REACT_APP_API_URL}/storage/${initialData?.img}`}
                alt="#"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="mt-5">
            <MultipleFileInput name={"შიდა ფოტოები"} inputName={"images"} />
          </div>

          <div className="flex gap-2 flex-wrap">
            {initialData?.images?.split(",")?.map((item, index) => (
              <div className="w-20 h-20" key={index}>
                <img
                  src={`${process.env.REACT_APP_API_URL}/storage/${item}`}
                  alt="#"
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
          <MultyLangualTextInput
            name={"სათაური"}
            geInputName={"titleGe"}
            enInputName={"titleEn"}
            inputSetter={handleNewData}
            value={initialData?.titleGe}
            valueen={initialData?.titleEn}
          />
          <MultyLangualTextArea
            name={"მოკლე აღწერა"}
            geInputName={"smalTextGe"}
            enInputName={"smalTextEn"}
            inputSetter={handleNewData}
            value={initialData?.smalTextGe}
            valueen={initialData?.smalTextEn}
          />
          <MultyLangualTextArea
            name={"ვრცელი აღწერა"}
            geInputName={"textGe"}
            enInputName={"textEn"}
            inputSetter={handleNewData}
            value={initialData?.textGe}
            valueen={initialData?.textEn}
          />
        </div>
      </form>
    </div>
  );
};

export default InnerBlogAdmin;
