import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { SelectInputValueId, TextInput } from "../../inputComponents/inputcomp";
import { successToast } from "../../toastAlerts/ToastAlerts";
import PaginatedItems from "../../Pagination/Pagination";
import { BiLoaderAlt } from "react-icons/bi";
import DeclarationServiceCard from "./DeclarationServiceCard";

const DeclarationService = () => {
  //search toggler
  const [searchToggler, setSearchToggler] = useState(false);
  //selected user
  const [selectedUser, setSelectedUser] = useState("-Search user-");
  //searched user
  const [searchedUser, setSearchedUser] = useState(null);
  //new data
  const [addNewData, setAddNewData] = useState({});
  //all data
  const [allData, setAllData] = useState([]);
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //total count
  const [total, setTotal] = useState(0);
  //is loading state
  const [isLoading, setIsLoading] = useState(false);
  //update data
  const [update, setUpdate] = useState(null);
  //flights
  const [flights, setFlights] = useState([]);

  //handle Payload
  const handlePayloadBarcode = (value, inputName) => {
    setAddNewData((prev) => ({ ...prev, [inputName]: value }));
  };

  //receive package functionality
  const handleFindUser = async (e) => {
    try {
      const res = await axiosAdmin.get(
        `/users/search?searchValue=${e.target.value}`
      );
      if (res) {
        setSearchedUser([res.data]);
      }
    } catch (err) {}
  };

  //add new
  const handleAddNewService = () => {
    axiosAdmin
      .post("/declaration", addNewData)
      .then((res) => {
        setUpdate(res);
        successToast("წარმატებით დაემატა");
      })
      .catch((err) => {});
  };

  //add new
  const handleGetAllRecord = () => {
    setIsLoading(true);
    axiosAdmin
      .get(`/declaration?page=${currentPage + 1}&per_page=8`)
      .then(({ data }) => {
        setAllData(data.data);
        setTotal(data.total);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  const deleteService = (id) => {
    axiosAdmin
      .delete(`/declaration/${id}`)
      .then((res) => {
        setUpdate(res);
        successToast("წარმატებით წაიშალა");
      })
      .catch((err) => {});
  };

  //const choosing user
  const handleChooseUser = (value, userId) => {
    setSelectedUser(value);
    handlePayloadBarcode(userId, "user_id");
  };

  useEffect(() => {
    handleGetAllRecord();
  }, [update, currentPage]);

  useEffect(() => {
    axiosAdmin.get(`/shipments`).then(({ data }) => {
      setFlights(
        data.data.map((item) => {
          return {
            name: item.code,
            value: item.id,
          };
        })
      );
    });
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
        min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="grid grid-cols-4 gap-5">
        <div className="flex flex-col gap-2 relative">
          <div
            className="flex flex-col mt-2"
            onClick={() => {
              setSearchToggler((prev) => !prev);
            }}
          >
            <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">
              ოთახის ნომერი
            </p>
            <div
              className="h-10 w-full pl-1.5 dark:bg-slate-800 border border-gray-300 shadow rounded-lg
                                                flex items-center bg-white"
            >
              <p className="text-gray-500">{selectedUser}</p>
            </div>
          </div>
          {searchToggler && (
            <div
              className="border border-gray-300 shadow-sm rounded-lg p-2 bg-white
                                            flex flex-col gap-2 absolute top-[105%] left-0 w-full"
            >
              <input
                type="text"
                onChange={(e) => {
                  handleFindUser(e);
                }}
                className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                                                dark:bg-slate-800 border border-gray-300 shadow rounded-lg"
              />
              {searchedUser &&
                searchedUser.map((item) => {
                  return (
                    <div key={item.id}>
                      {item.company_id === undefined ||
                      item.company_id === null ? (
                        <p
                          onClick={() => {
                            handleChooseUser(
                              `EG${item.id} - ${item.fname} ${item.lname}`,
                              item.id
                            );
                            setSearchToggler(false);
                          }}
                          className="text-gray-500 text-sm"
                        >
                          {`${item.id} - ${item.fname_eng} ${item.lname_eng}`}
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            handleChooseUser(
                              `EG${item.id} - ${item.cname}`,
                              item.id
                            );
                            setSearchToggler(false);
                          }}
                          className="text-gray-500 text-sm"
                        >
                          {`${item.id} - ${item.cname}`}
                        </p>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <SelectInputValueId
          name={"რეისი"}
          inputName={"shipment_id"}
          inputSetter={handlePayloadBarcode}
          options={flights}
        />

        <TextInput
          name={"თანხა"}
          inputName={"price"}
          inputSetter={handlePayloadBarcode}
          placeholder={"თანხა"}
        />

        <div className="flex items-end">
          <button
            className="bg-green-400 text-white rounded-lg p-3 w-full"
            onClick={handleAddNewService}
          >
            {" "}
            შენახვა
          </button>
        </div>
      </div>

      <div className="mt-10">
        <PaginatedItems
          pageNumberSetter={setCurrentPage}
          dataLength={total}
          itemsPerPage={8}
        >
          {isLoading ? (
            <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
              <BiLoaderAlt className="w-20 h-20 animate-spin text-green-500" />
            </div>
          ) : isLoading === false && allData.length === 0 ? (
            <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
              <p className="text-slate-600 text-sm">მონაცემები არ მოიძებნა</p>
            </div>
          ) : (
            <div className="w-full flex flex-col gap-2">
              {allData.map((item) => {
                return (
                  <DeclarationServiceCard
                    key={item.id}
                    item={item}
                    deleteService={deleteService}
                  />
                );
              })}
            </div>
          )}
        </PaginatedItems>
      </div>
    </div>
  );
};

export default DeclarationService;
