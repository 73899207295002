import { useState, useEffect, useContext } from "react";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import {
  DateInput,
  SelectInputValueId,
  TextInput,
} from "../../inputComponents/inputcomp";
import PromoCodeCard from "./PromoCodeCard";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

const PromoCodes = () => {
  const { abOffices } = useContext(AdminPrimaryContext);
  const [codeData, setCodeData] = useState([]);
  const [newData, setNewData] = useState({});
  const [addNewToggler, setAddNewToggler] = useState(false);

  const handleNewData = (value, inputName) => {
    setNewData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  const handleFetchData = async () => {
    try {
      const res = await axiosAdmin.get(`/promokodi`);
      setCodeData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddNewCode = async () => {
    if (!newData.name || !newData.percent) {
      errorToast("სახელი ან ფასდაკლების პროცენტი ცარიელია");
      return;
    }

    try {
      const res = await axiosAdmin.post(`/promokodi`, {
        ...newData,
      });
      if (res) {
        handleFetchData();
        successToast("კოდი წარმატებით დაემატა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axiosAdmin.delete(`/promokodi/${id}`);
      if (res) {
        handleFetchData();
        successToast("წარმატებით წაიშალა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
      <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0"
          >
            ახალი კოდის დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0">
            <div className="sm:px-0">
              <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                <div className="flex items-center justify-between flex-wrap gap-5">
                  <div className="flex items-center gap-5 sm:gap-3 ">
                    <div>
                      <h2
                        className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                      >
                        ახალი კოდის დამატება
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 sm:w-full">
                    <button
                      onClick={handleAddNewCode}
                      className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                    >
                      შენახვა
                    </button>
                    <button
                      onClick={() => {
                        handleAddNewToggle(false);
                      }}
                      className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                    >
                      X
                    </button>
                  </div>
                </div>
                <div
                  className="pt-5 grid grid-cols-2
                 max-sm:grid-cols-1 gap-5"
                >
                  <SelectInputValueId
                    name={"ოფისი"}
                    inputName={"ao_id"}
                    inputSetter={handleNewData}
                    options={abOffices.map((item) => {
                      return { name: item.country, value: item.id };
                    })}
                  />
                  <TextInput
                    name={"კოდი"}
                    inputName={"name"}
                    inputSetter={handleNewData}
                  />
                  <TextInput
                    name={"ფასდაკლება % (მაგ : 20)"}
                    inputName={"percent"}
                    inputSetter={handleNewData}
                  />
                  <DateInput
                    name={"თარიღიდან"}
                    inputName={"date_from"}
                    inputSetter={handleNewData}
                  />
                  <DateInput
                    name={"თარიღამდე"}
                    inputName={"date_to"}
                    inputSetter={handleNewData}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen">
        <div className="grid px-10 max-sm:px-5 gap-5">
          {codeData.map((obj) => {
            return (
              <PromoCodeCard
                key={obj.id}
                id={obj.id}
                name={obj.name}
                handleDeleteFilial={handleDelete}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PromoCodes;
