import { useTranslation } from "react-i18next";
import TextArea from "../../components/InputComponents/TextArea";
import { useContext, useState } from "react";
import { FaRegFaceSmileWink } from "react-icons/fa6";
import logo from "../../images/mglLogo.png";
import axiosClient from "../../api/api";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { useParams } from "react-router-dom";
import { PrimaryContext } from "../../components/MainContext/MainContext";

const Review = () => {
  const { t } = useTranslation();
  const stars = Array.from({ length: 5 }, (_, i) => i + 1);
  const { setLanguage, language } = useContext(PrimaryContext);
  const [newReview, setNewReview] = useState({});
  const [finished, setFinished] = useState(false);
  const { userId } = useParams();

  const handleNewReview = (value, name) => {
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateNewReview = () => {
    if (!newReview.score || !newReview.comment) {
      errorToast(t("fill_all_fields"));
      return;
    }

    axiosClient
      .post(`/rateus`, {
        ...newReview,
        question_id: 1,
        user_id: userId,
      })
      .then((res) => {
        setFinished(true);
      })
      .catch((err) => {});
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center p-10">
      <div className="flex flex-col justify-between gap-8 w-[400px] md:w-full">
        <div className="flex justify-between w-full">
          <img src={logo} alt="logo" className="w-[160px]" />
          <div className="flex items-center gap-2">
            <p
              onClick={() => {
                setLanguage("ge");
              }}
              className={`text-md max-w-[400px] sm:max-w-[280px] cursor-pointer mt-1 ${
                language === "ge" ? "text-teal_600" : ""
              }`}
            >
              GE
            </p>
            <p
              onClick={() => {
                setLanguage("en");
              }}
              className={`text-md max-w-[400px] sm:max-w-[280px] cursor-pointer mt-1 ${
                language === "en" ? "text-teal_600" : ""
              }`}
            >
              EN
            </p>
          </div>
        </div>

        {finished ? (
          <></>
        ) : (
          <>
            <h1 className="text-center text-2xl">{t("rateUs")}</h1>
            <TextArea
              placeholder={t("leaveReview")}
              inputName={"comment"}
              inputSetter={handleNewReview}
            />
            <div className="gap-2 grid grid-cols-5">
              {stars?.map((star) => {
                return (
                  <FaRegFaceSmileWink
                    key={star}
                    onClick={() => {
                      handleNewReview(star, "score");
                    }}
                    className={`cursor-pointer text-6xl md:text-4xl ${
                      star <= newReview?.score
                        ? "text-yellow-400"
                        : "text-slate-400"
                    }`}
                  />
                );
              })}
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={handleCreateNewReview}
                className="text-white bg-teal_600 py-3
px-10 text-sm self-start rounded-[5px]"
              >
                {t("rateUs")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Review;
