import Line from "../../components/InputComponents/Line";
import ParcelsMenu from "./ParcelsMenu";
import { useContext, useEffect, useState } from "react";
import axiosClient from "../../api/api";
import UserPagination from "../../components/UserPagination/UserPagination";
import { BiSearch } from "react-icons/bi";
import WarehouseCard from "./WarehouseCard";
import SentCard from "./SentCard";
import ArrivedCard from "./ArrivedCard";
import TakeoutCard from "./TakeoutCard";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";
import Button from "../../components/InputComponents/Button";
import { errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";

const MyParcels = () => {
  const { t } = useTranslation();
  //parcels
  const [parcels, setParcels] = useState([]);
  //updater
  const [updatePacks, setUpdatePacks] = useState(null);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //type
  const [type, setType] = useState(1);
  //search
  const [searchValue, setSearchValue] = useState("");
  //data from context
  const { updateUser, setUpdateUser } = useContext(PrimaryContext);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(
        `/packs?page=${
          currentPage + 1
        }&per_page=${12}&status=${type}&tracking_number=${searchValue}`
      )
      .then(({ data }) => {
        setParcels(data.data);
        setTotalAmount(data.meta.total);
        setIsLoading(false);
      });
  }, [currentPage, type, searchValue, updatePacks]);

  useEffect(() => {
    setCurrentPage(0);
  }, [type]);

  //handle pay
  const payPack = (id) => {
    axiosClient.post(`/pakcPay/${id}`).then((res) => {
      setUpdatePacks(res);
      updateUser(res);
    });
  };

  //handle pay with bonus points
  const payPackBonusPoints = (id) => {
    axiosClient.post(`/pakcPay/${id}?method=points`).then((res) => {
      setUpdatePacks(res);
      updateUser(res);
    });
  };

  //pay all
  const handlePayAll = (packageType) => {
    axiosClient
      .get(`/payAll?status=${packageType}`)
      .then((res) => {
        errorToast(t("successfully"));
        setUpdatePacks(res);
        setUpdateUser(res);
      })
      .catch((err) => {
        errorToast(t("error_msg"));
      });
  };

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="text-black_900 text-2xl md:text-xl font-semibold">
          {t("my_parcels")}
        </h2>
      </div>
      <ParcelsMenu setter={setType} />
      <div className="flex gap-2 mt-5 sm:flex-col">
        <div className="w-full flex items-center gap-2">
          <div
            className="flex items-center gap-4 w-full bg-white shadow-bs1
              font-normal h-12 md:max-w-full px-2 py-2 rounded-[12px] text-gray_600 text-left"
          >
            <input
              placeholder={t("tracking_number")}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              className="bg-transparent w-full"
            />
            <div className="w-10 h-10 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600">
              <BiSearch className="w-full h-full text-white" />
            </div>
          </div>
        </div>
        {(type === 2 || type === 3) && (
          <Button
            className="cursor-pointer flex items-center justify-center min-w-[310px] w-auto sm:w-full sm:min-w-full"
            shape="RoundedBorder14"
            size="lg"
            variant="OutlineTeal600"
            onClick={() => {
              handlePayAll(type);
            }}
          >
            <div className="font-normal text-md text-center text-white_A700">
              {t("pay_all")}
            </div>
          </Button>
        )}
      </div>
      <UserPagination
        dataLength={totalAmount}
        itemsPerPage={12}
        pageNumberSetter={setCurrentPage}
      >
        <div
          className={`afMd:shadow-bs1 afMd:rounded-[30px] my-5
  afMd:bg-white ${
    parcels?.length !== 0 && "md:grid md:grid-cols-2 md:gap-5 sm:grid-cols-1 "
  }`}
        >
          {isLoading ? (
            dummyArray.map((_, index) => {
              return (
                <div
                  className={`animate-pulse bg-gray-200 w-full h-16 md:h-32 md:rounded-lg border-t border-white ${
                    index === 0 && "afMd:border-t-0 afMd:rounded-t-3xl"
                  } ${dummyArray.length - 1 === index && "afMd:rounded-b-3xl"}`}
                  key={index}
                ></div>
              );
            })
          ) : parcels?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            parcels.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`${
                    index !== 0 &&
                    parcels.length !== 0 &&
                    "border-t border-gray-200 md:border-t-0"
                  }`}
                >
                  {" "}
                  {type === 1 && (
                    <WarehouseCard
                      data={item}
                      payPack={payPack}
                      setUpdatePacks={setUpdatePacks}
                      payPackBonusPoints={payPackBonusPoints}
                    />
                  )}
                  {type === 2 && (
                    <SentCard
                      data={item}
                      payPack={payPack}
                      setUpdatePacks={setUpdatePacks}
                      payPackBonusPoints={payPackBonusPoints}
                    />
                  )}
                  {type === 3 && (
                    <ArrivedCard
                      data={item}
                      payPack={payPack}
                      setUpdatePacks={setUpdatePacks}
                      payPackBonusPoints={payPackBonusPoints}
                    />
                  )}
                  {type === 4 && (
                    <TakeoutCard
                      data={item}
                      className={`${
                        index !== 0 &&
                        parcels.length !== 0 &&
                        "border-t border-gray-200 md:border-t-0"
                      }`}
                    />
                  )}
                </div>
              );
            })
          )}
        </div>
      </UserPagination>
    </div>
  );
};

export default MyParcels;
