import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { TextInput, SelectInputValueId } from "../../inputComponents/inputcomp";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";

//icons
import { BiUser } from "react-icons/bi";
import { BsEnvelope, BsLock } from "react-icons/bs";

//images
import logo from '../../../img/phoneLogo.png';

const InnerUsersPage = () => {
    //get current user id 
    const { usersId } = useParams();
    //new admin data
    const [adminData, setAdminData] = useState({
        role: 1,
    });
    //errors
    const [error, setError] = useState(null);

    //handle new admin
    const handleNewAdminData = (value, inputName) => {
        setAdminData(prev => ({ ...prev, [inputName]: value }))
    }

    //add new admin 
    const handleEditAdmin = async () => {
        try {
            const res = await axiosAdmin.put(`/admins/${usersId}`, adminData);
            if (res) {
                successToast("მონაცემები წარმატებით განახლდა!");
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //admin data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/admins/${usersId}`)
                .then(({ data }) => {
                    setAdminData({ ...data, pass: "" });
                });
        } catch (error) {
            setError(error);
        }
    }, []);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                    <div className="flex items-center justify-between flex-wrap gap-5">
                        <div className="flex items-center gap-5 sm:gap-3">
                            <div className="w-[100px] h-10">
                                <img src={logo} className="w-full h-full" />
                            </div>
                            <div>
                                <h2 className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]">ადმინის პროფილის რედაქტირება</h2>
                            </div>
                        </div>
                        <button
                            onClick={handleEditAdmin}
                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                            შენახვა
                        </button>
                    </div>
                    <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"სახელი"}
                                Icon={BiUser}
                                inputName={"name"}
                                inputSetter={handleNewAdminData}
                                value={adminData.name}
                            />
                            <TextInput
                                name={"ელ-ფოსტა"}
                                inputName={"email"}
                                inputSetter={handleNewAdminData}
                                Icon={BsEnvelope}
                                value={adminData.email}
                            />
                        </div>
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"პაროლი"}
                                inputName={"password"}
                                inputSetter={handleNewAdminData}
                                Icon={BsLock}
                            />
                            <SelectInputValueId
                                name={"ადმინის როლი"}
                                inputName={"role"}
                                inputSetter={handleNewAdminData}
                                options={[
                                    {
                                        name: "სუპერ ადმინი",
                                        value: 100
                                    },
                                    {
                                        name: "Turkey ოპერატორი",
                                        value: 1
                                    },
                                    {
                                        name: "Sea - China ოპერატორი",
                                        value: 2
                                    },
                                    {
                                        name: "Air - China ოპერატორი",
                                        value: 3
                                    },
                                ]}
                                value={adminData.role}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InnerUsersPage;