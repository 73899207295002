import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { BiLoaderAlt, BiMap } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import axiosClient from "../../../api/api";
import { BsEnvelope, BsTelephone } from "react-icons/bs";
import { PrimaryContext } from "../../MainContext/MainContext";

const ContactSection = () => {
  const { t } = useTranslation();
  //branches data
  const [branches, setBranches] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //dummy text for loader
  const dummyArray = Array.from({ length: 3 });
  //context data
  const { language } = useContext(PrimaryContext);

  //fetch branches data
  useEffect(() => {
    setIsLoading(true);
    axiosClient.get("/offices").then(({ data }) => {
      setBranches(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="px-12 md:px-6 w-full flex flex-col items-center justify-center">
      <div className="w-full max-w-[80%] md:max-w-[100%] ">
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <h6 className="text-black_900 text-2xl md:text-xl font-bold">
            {t("contact")}
          </h6>
        </div>
        <div className="w-full mt-12 md:mt-6 flex flex-col gap-5 min-h-[200px]">
          <div className="flex gap-5 md:flex-col">
            <div className="afMd:w-7/12 relative sm:h-[40vh]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.602374400988!2d41.65546837581197!3d41.57784958415997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40678f7d178d2ceb%3A0xd917b9b953ad89c0!2z4YOk4YOg4YOY4YOT4YOd4YOcIOGDruGDkOGDmuGDleGDkOGDqOGDmOGDoSDhg6Xhg6Phg6nhg5A!5e0!3m2!1sen!2sge!4v1692344590972!5m2!1sen!2sge"
                width="100%"
                height="100%"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-2xl outline-none"
              ></iframe>

              <a
                href="tel:+995322453990"
                className="inline-block absolute left-0 bottom-10 md:bottom-5"
              >
                <div className="bg-teal_600 text-white rounded-r-2xl p-2">
                  <div className="flex items-center gap-2">
                    <BsTelephone className="text-[18px]" />
                    <p className="text-base">+995 322 45 39 90</p>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <BsEnvelope className="text-[18px]" />
                    <p className="text-base">supportparcel@mgl.ge</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="afMd:w-5/12">
              {isLoading ? (
                <>
                  <div className="flex flex-col gap-5">
                    {dummyArray.map((_, index) => {
                      return (
                        <div
                          className="animate-pulse bg-slate-100 w-full h-20 rounded-xl"
                          key={index}
                        ></div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="flex flex-col gap-4 sm:gap-5 w-full">
                  {branches.map((item) => {
                    return (
                      <div
                        className="flex items-center gap-2 p-6 rounded-2xl w-full
                                    border-2 border-solid border-[#F0F0F0] md:p-3"
                        key={item.id}
                      >
                        <div className="shrink-0">
                          <FaMapMarkerAlt className="text-teal_600 text-[42px]" />
                        </div>
                        <div className="flex flex-col gap-1 w-full">
                          <h6 className="text-xl md:text-base font-bold">
                            {language === "ge"
                              ? item.name.split(",")[0]
                              : item.name.split(",")[1]}
                          </h6>
                          <p className="text-sm md:text-xs font-bold">
                            {language === "ge"
                              ? item.address.split(",")[0]
                              : item.address.split(",")[1]}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
