import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";

const AdminReviews = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosAdmin
      .get(`/rateus`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
      <div className="min-h-screen p-10">
        <div className="grid grid-cols-1 gap-5">
          {data.map((item) => {
            return (
              <div
                key={item?.id}
                className="bg-white rounded-lg border border-slate-300 p-5 grid gap-5"
              >
                <div>
                  <p className="text-xs mt-2">მომხმარებელი:</p>
                  <p className="text-xs mt-2"> {item?.user?.id}</p>
                  <p className="text-xs mt-2">{item?.user?.email}</p>
                  <p className="text-xs mt-2">{item?.user?.phone}</p>
                </div>
                <p>შეფასება: {item?.score}</p>
                <p>აღწერა: {item?.comment}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminReviews;
