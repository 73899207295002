import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const PromoCodeCard = ({ id, name, handleDeleteFilial }) => {
  const [showContinue, setShowContinue] = useState(false);

  return (
    <div className="bg-white rounded-xl p-5 w-full relative flex  items-center justify-between gap-3 dark:bg-slate-700">
      <h2 className="text-[14px] font-bold text-[#777a83] dark:text-navy-100 dark:text-gray-200">
        {name}
      </h2>
      <div className="flex gap-2">
        <Link
          to={`../promo-codes/${id}`}
          className="h-10 rounded-lg bg-violet-400 shrink-0
                 flex gap-2 items-center px-5"
        >
          <PencilSquare className="text-white font-bold shrink-0" />
          <p className="text-white text-sm">რედაქტირება</p>
        </Link>
        <div
          onClick={() => {
            setShowContinue(true);
          }}
          className="w-10 h-10 rounded-lg bg-red-400 cursor-pointer shrink-0 p-3"
        >
          <BsTrash className="text-white w-full h-full" />
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeleteFilial}
        setShowContinue={setShowContinue}
        id={id}
      />
    </div>
  );
};

export default PromoCodeCard;
