import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import {
  CheckBox,
  SelectInputValueId,
} from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import AppeardPackagesItem from "./AppeardPackagesItem";
import PaginatedItems from "../../../Pagination/Pagination";
import { FaRegHandPointRight } from "react-icons//fa";
import { BiLoaderAlt } from "react-icons/bi";
import { BsX } from "react-icons/bs";

const AppeardPackages = () => {
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(0);
  //branches data
  const { foreignAdresses, geBranches } = useContext(AdminPrimaryContext);
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //package check all
  const [checkAll, setCheckAll] = useState(false);
  //search value and transportation
  const [search, setSearch] = useState({
    key: "",
  });
  //selected Packages
  const [selectedPackages, setSelectedPackages] = useState({
    pack_id: [],
  });
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //total count
  const [total, setTotal] = useState(0);
  //shipment code for express
  const [shipmentCode, setShipmentCode] = useState("");
  //is loading state
  const [isLoading, setIsLoading] = useState(false);
  //transportation
  const [transportation, setTransportation] = useState({
    transport: "",
    secured: "",
    geOffice: "",
  });
  //shipments
  const [shipments, setShipments] = useState([]);

  //handle selected Packages
  const handleSelectedPackages = (value) => {
    if (!selectedPackages.pack_id.includes(value)) {
      setSelectedPackages((prev) => ({ pack_id: [...prev.pack_id, value] }));
    }
  };
  //handle delete selected Packages
  const handleDeleteSelectedPackages = (value) => {
    setSelectedPackages((prev) => ({
      pack_id: prev.pack_id.filter((obj) => {
        return obj !== value;
      }),
    }));
  };

  //handle change search value
  const handleSearchvalue = (e, inputName) => {
    setSearch((prev) => ({ ...prev, [inputName]: e.target.value }));
  };

  //handle transportation value
  const handleSearchTransport = (value, inputName) => {
    setTransportation((prev) => ({ ...prev, [inputName]: value }));
  };

  //handle checking
  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setCurrentPlaceId(value);
    setCheckAll(false);
    setSelectedPackages({
      pack_id: [],
    });
  };

  //const handle withdrawal package
  const handleSendAllWithdrawalPackages = async () => {
    try {
      const res = await axiosAdmin.post(`/packs/set`, {
        status: "4",
        ids: [...selectedPackages.pack_id],
      });
      if (res) {
        successToast("მოქმედება წარმატებით განხორციელდა!");
        setDifResponses(res);
        setSelectedPackages({ pack_id: [] });
      }
    } catch (err) {
      if (err.response.status === 402) {
        errorToast(
          "ამანათის საფასური გადასახდელია ან დეკლარაციის საფასურია გადასახდელი"
        );
      } else {
        errorToast(
          "ამანთებში დაფიქსირდა გადაუხდელი ამანათი ან წარმოიშვა სხვა სახის პრობლემა ცადეთ ხელახლა"
        );
      }
    }
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.delete(`/packs/${id}`);
      if (res) {
        setDifResponses(res);
        successToast("ამანათი წარმატებით წაიშალა!");
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //handle show package
  const handleShowAllPackages = async () => {
    try {
      const res = await axiosAdmin.post(`/packs/set`, {
        status: "3",
        ids: [...selectedPackages.pack_id],
      });
      if (res) {
        setCheckAll(false);
        setSelectedPackages({
          pack_id: [],
        });
        setDifResponses(res);
        successToast("წარმათებით გამოჩნდა მომხმარებლისთვს!");
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //data fetching
  const handleParcelInfo = () => {
    setIsLoading(true);
    axiosAdmin
      .get(
        `/packs?status=${3}&abroad_office_id=${currentPlaceId}&page=${
          currentPage + 1
        }` +
          `&per_page=${20}&addon_transp=${
            transportation.transport
          }&searchValue=${search.key}` +
          `&secured=${transportation.secured}` +
          `&repack=${
            transportation.repack === "false" ? "" : transportation.repack
          }` +
          `&office_id=${transportation.geOffice ? transportation.geOffice : ""}`
      )
      .then(({ data }) => {
        setPackageData(data.data);
        setTotal(data.meta.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //handle sent pack back
  const handleSendBack = (id) => {
    axiosAdmin
      .get(`/packs/back/${id}`)
      .then((res) => {
        successToast("ამანათები დაბრუნდა წინა სექციაში!");
        setDifResponses(res);
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //update user
  const handleGiveUserToParcel = (newData, packId) => {
    axiosAdmin
      .put(`/packs/${packId}`, {
        user_id: newData.userId,
        total_weight: newData.weight,
        shipment_code: newData.flightNumber,
        transport_price: newData.transport_price,
        dementionY: newData.dementionY,
        dementionZ: newData.dementionZ,
        dementionX: newData.dementionX,
        addon_secur: newData.addon_secur,
      })
      .then((res) => {
        successToast("წარმატებით შეიცვალა!");
        setDifResponses(res);
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //on barcode scanner using
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelInfo();
    }
  };

  const handleChangeTransportation = (parcelId) => {
    axiosAdmin
      .put(`/packs/${parcelId}`, {
        addon_transp: "",
        add_tr_price: "",
      })
      .then((res) => {
        setDifResponses(res);
        successToast("successful");
      })
      .catch(() => {
        errorToast("error");
      });
  };

  //package data fetching
  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  //package data fetching
  useEffect(() => {
    handleParcelInfo();
  }, [currentPlaceId, difResponses, transportation, currentPage]);

  //package data fetching
  useEffect(() => {
    if (selectedPackages.pack_id.length > 0) {
      setCheckAll(false);
      setSelectedPackages({
        pack_id: [],
      });
    }
  }, [currentPage]);

  useEffect(() => {
    axiosAdmin
      .get(`/shipments?per_page=500`)
      .then(({ data }) => {
        setShipments((prev) => [
          ...data.data.map((item) => {
            return {
              value: item.code,
              name: item.code,
            };
          }),
        ]);
      })
      .catch((err) => {});
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full overflow-auto scrollbar mb-5">
        <div className="flex gap-1 w-full md:w-[1000px] p-2">
          {foreignAdresses.map((item) => {
            return (
              <div
                key={item.id}
                className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
                    shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] 
                    ${
                      currentPlaceId === item.id
                        ? "border border-green-500 bg-green-100"
                        : "bg-white dark:bg-slate-700 "
                    }`}
              >
                <div className="w-full flex flex-col gap-2">
                  <p className="text-[14px] text-[#8e95a8]">
                    {`${item.country} address`}
                  </p>
                  <div className="flex gap-1">
                    <div
                      onClick={() => {
                        handleCurrentPlaceId(item.id);
                      }}
                      className="w-full rounded-lg bg-green-400
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
                    >
                      <FaRegHandPointRight />
                      <p>აირჩიე</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <h2 className="text-slate-400 text-2xl">ჩამოსული ამანათები</h2>
      <div className="w-full">
        <div className="bg-yellow mt-2 flex gap-1 sm:flex-col">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-9/12 sm:w-full"
          >
            <input
              type="search"
              placeholder="ძებნა"
              onChange={(e) => {
                handleSearchvalue(e, "key");
              }}
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className="flex items-center w-full">
            <SelectInputValueId
              options={shipments}
              inputName={"shipmentId"}
              inputSetter={(flight_num, _) => {
                setShipmentCode(flight_num);
              }}
              value={shipmentCode}
              defaultText={"რეისის ნომერი ექსელის ექსპორტისთვის"}
            />
            <BsX
              className="text-red-500 text-2xl shrink-0 cursor-pointer"
              onClick={() => {
                setShipmentCode("");
              }}
            />
          </div>
          <Link
            to={
              `${
                process.env.REACT_APP_API_URL
              }/api/exportPacks?status=${3}&abroad_office_id=${currentPlaceId}` +
              `&shipment_code=${shipmentCode}` +
              `&repack=${
                transportation.repack === "false" ? "" : transportation.repack
              }`
            }
            className="w-3/12 rounded-lg bg-green-400 sm:w-full
                 flex items-center justify-center p-1 py-3 text-white"
          >
            EXPORT CSV
          </Link>
        </div>
        <div className="flex">
          <div className="flex items-center mt-2">
            <SelectInputValueId
              options={geBranches}
              inputName={"geOffice"}
              inputSetter={handleSearchTransport}
              value={shipmentCode}
              defaultText={"საქართველოს ფილიალები"}
            />
            <BsX
              className="text-red-500 text-2xl shrink-0 cursor-pointer"
              onClick={() => {
                handleSearchTransport("geOffice", "");
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between my-2 flex-wrap gap-3 mt-2">
          <div className="flex gap-2 items-center sm:order-2 sm:flex-col sm:items-start">
            <CheckBox
              name={"ტრანსპორტირება"}
              inputName={"transport"}
              handleCarData={handleSearchTransport}
            />
            <CheckBox
              name={"დაზღვეული ამანათები"}
              inputName={"secured"}
              handleCarData={handleSearchTransport}
            />
            <div className="flex items-center gap-3">
              <input
                checked={checkAll}
                onChange={(e) => {
                  handleCheckAll(e);
                }}
                type="checkbox"
              />
              <p className="text-sm text-gray-500">ყველას მონიშვნა</p>
            </div>
            <CheckBox
              name={"გადაფუთული ამანათები"}
              inputName={"repack"}
              handleCarData={handleSearchTransport}
            />
          </div>
          <div className="flex gap-2 sm:flex-wrap">
            <div
              onClick={handleShowAllPackages}
              className="rounded-lg bg-yellow-400 sm:w-full
                 flex items-center justify-center gap-1 p-1 py-3 text-white cursor-pointer"
            >
              <p>მომხმარებლისთვის ჩამოსულებში გამოჩენა</p>
            </div>
            <div
              onClick={handleSendAllWithdrawalPackages}
              className="rounded-lg bg-green-400 sm:w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
            >
              <p>მონიშნულის გაცემა</p>
            </div>
          </div>
        </div>
        <div className="w-full flex gap-3 flex-wrap mt-5">
          <PaginatedItems
            pageNumberSetter={setCurrentPage}
            dataLength={total}
            itemsPerPage={20}
          >
            {isLoading ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <BiLoaderAlt className="w-20 h-20 animate-spin text-green-500" />
              </div>
            ) : isLoading === false && packageData.length === 0 ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <p className="text-slate-600 text-sm">მონაცემები არ მოიძებნა</p>
              </div>
            ) : (
              <div className="w-full flex flex-col gap-2">
                {packageData.map((item) => {
                  return (
                    <AppeardPackagesItem
                      key={item.id}
                      data={item}
                      handleDeletePackage={handleDeletePackage}
                      setCheckedAll={checkAll}
                      handleSelectedPackages={handleSelectedPackages}
                      handleDeleteSelectedPackages={
                        handleDeleteSelectedPackages
                      }
                      setDifResponses={setDifResponses}
                      handleSendBack={handleSendBack}
                      handleGiveUserToParcel={handleGiveUserToParcel}
                      handleChangeTransportation={handleChangeTransportation}
                    />
                  );
                })}
              </div>
            )}
          </PaginatedItems>
        </div>
      </div>
    </div>
  );
};

export default AppeardPackages;
