import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  DateInput,
  SelectInputValueId,
  TextInput,
} from "../../inputComponents/inputcomp";
import logo from "../../../img/phoneLogo.png";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

const InnerPromoCodes = () => {
  const { codeId } = useParams();
  const { abOffices } = useContext(AdminPrimaryContext);
  const [data, setData] = useState({});

  const handleNewData = (value, inputName) => {
    setData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleFetchData = async () => {
    try {
      const res = await axiosAdmin.get(`/promokodi/${codeId}`);
      setData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditData = async () => {
    try {
      const res = await axiosAdmin.put(`/promokodi/${codeId}`, {
        ...data,
      });
      if (res) {
        handleNewData();
        successToast("მონაცემები წარმატებით განახლდა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  console.log(data);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-[100px] h-10">
                <img src={logo} className="w-full h-full" />
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  კოდის რედაქტირება
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditData}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <div
            className="pt-5 grid grid-cols-2
                 max-sm:grid-cols-1 gap-5"
          >
            <SelectInputValueId
              name={"ოფისი"}
              inputName={"ao_id"}
              inputSetter={handleNewData}
              options={abOffices.map((item) => {
                return { name: item.country, value: item.id };
              })}
              value={data.ao_id}
            />
            <TextInput
              name={"კოდი"}
              inputName={"name"}
              inputSetter={handleNewData}
              value={data.name}
            />
            <TextInput
              name={"ფასდაკლება % (მაგ : 20)"}
              inputName={"percent"}
              inputSetter={handleNewData}
              value={data.percent}
            />
            <DateInput
              name={"თარიღიდან"}
              inputName={"date_from"}
              inputSetter={handleNewData}
              value={data.date_from}
              manualSplitValue={true}
            />
            <DateInput
              name={"თარიღამდე"}
              inputName={"date_to"}
              inputSetter={handleNewData}
              value={data.date_to}
              manualSplitValue={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerPromoCodes;
