import Line from "../../components/InputComponents/Line";
import TextInput from "../../components/InputComponents/TextInput";
import TextArea from "../../components/InputComponents/TextArea";
import Button from "../../components/InputComponents/Button";
import { successToast, errorToast } from "../../adminPanel/components/toastAlerts/ToastAlerts";
import { useState } from "react";
import axiosClient from "../../api/api";
import { useTranslation } from "react-i18next";


const Support = () => {
    const { t } = useTranslation();
    //new message
    const [newMessage, setNewMessage] = useState({
        type: 3
    })

    const handleMessage = (value, inputName) => {
        setNewMessage(prev => ({ ...prev, [inputName]: value }))
    }

    const handleSendMessage = () => {
        axiosClient.post("/message", newMessage)
            .then(() => {
                successToast("შეტყობინება გაიგზავნა!")
            })
            .catch(() => {
                errorToast("დაფიქსირდა შეცდომა!")
            })
    }

    return (
        <div>
            <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
                <Line className="bg-teal_600 h-1 w-10" />
                <h2 className="font-semibold text-2xl md:text-xl">
                    {t("support")}
                </h2>
            </div>
            <div className="shadow-bs1 rounded-[30px] mt-5 bg-white p-5
            flex flex-col gap-2">
                <p className="font-semibold text-base md:text-sm mb-5">
                    {t("got_questions_or_need_more_information")} {t("fill_in_your_information_and_we_will_get_back_to_you")}
                </p>
                <TextInput
                    name={t("name")}
                    placeholder={"type your name here..."}
                    inputSetter={handleMessage}
                    inputName={"name"}
                />
                <TextInput
                    name={t("email_address")}
                    placeholder={"Ex. forexample@gmail.com"}
                    inputSetter={handleMessage}
                    inputName={"mail"}
                />
                <TextInput
                    name={t("phone")}
                    placeholder={"Ex. +995 234 12 12 32"}
                    inputSetter={handleMessage}
                    inputName={"phone"}
                />
                <TextArea
                    name={t("message")}
                    placeholder={"Message us for any matters and we will answer you as soon as possible"}
                    inputSetter={handleMessage}
                    inputName={"message"}
                />
                <Button
                    className="cursor-pointer font-normal leading-[normal] text-md text-center text-white_A700 w-32"
                    shape="RoundedBorder11"
                    size="lg"
                    variant="OutlineTeal600"
                    onClick={handleSendMessage}
                >
                    {t("send")}
                </Button>
            </div>
        </div >
    )
}

export default Support;