import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";
import { FileInput } from "../../inputComponents/inputcomp";
import CountriesCard from "./CountriesCard";

const Countries = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);
    //ab office data from api
    const [countriesData, setCountriesData] = useState([]);
    //error
    const [error, setError] = useState(null);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new ab offices  
    const [newCountries, setNewCountries] = useState({})
    //clear fields after submit 
    const [makeEmpty, setMakeEmpty] = useState(false);

    //handle filed clear after submission
    const handleMakeEmpty = (value) => {
        setMakeEmpty(value);
    }

    //handle new admin
    const handleNewCountriesData = (value, inputName) => {
        setNewCountries(prev => ({ ...prev, [inputName]: value }))
    }

    //add new users handlers 
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //delete ab office
    const handleDeleteCountries = async (id) => {
        try {
            const res = await axiosAdmin.delete(`/countries/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ქვეყანა წარმატებით წაიშალა!");
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //ab office data
    useEffect(() => {
        try {
            axiosAdmin.get(`/countries`)
                .then(({ data }) => {
                    setCountriesData(data);
                });
        } catch (error) {
            setError(error);
        }
    }, [difResponses]);

    //add country data
    function handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        axiosAdmin.post(`/countries`, formData)
            .then((res) => {
                setDifResponses(res);
                successToast("ქვეყანა წარმატებით დაემატა!");
                handleMakeEmpty(true);
            })
            .catch((err) => {
                setError(err);
                errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
            });
    }

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="w-full flex items-center justify-end ">
                    <button
                        onClick={() => { handleAddNewToggle(true) }}
                        className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0">
                        ახალი ქვეყნის დამატება
                    </button>
                </div>
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0">
                        <div className="sm:px-0">
                            <form
                                onSubmit={(e) => { handleSubmit(e) }}
                                encType="multipart/form-data"
                            >
                                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                    <div className="flex items-center justify-between flex-wrap gap-5">
                                        <div className="flex items-center gap-5 sm:gap-3 ">
                                            <div>
                                                <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი ქვეყნის დამატება</h2>
                                            </div>
                                        </div>
                                        <div className="flex gap-2 sm:w-full">
                                            <button
                                                type="submit"
                                                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                                შენახვა
                                            </button>
                                            <button
                                                onClick={() => { handleAddNewToggle(false) }}
                                                className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                                X
                                            </button>
                                        </div>
                                    </div>
                                    <FileInput
                                        name={"ფოტოს ატვირთვა"}
                                        inputName={"img"}
                                    />
                                    <div className="w-full pt-5">
                                        <TextInput
                                            name={"ქვეყანა"}
                                            inputSetter={handleNewCountriesData}
                                            inputName={"name"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="grid grid-cols-2 sm:grid-cols-1  p-10 pt-0 sm:p-5 gap-2 sm:pt-0">
                    {countriesData.map((item) => {
                        return (
                            < CountriesCard
                                item={item}
                                key={item.id}
                                id={item.id}
                                handleDeleteCountries={handleDeleteCountries}
                            />
                        )
                    })}
                </div>
            </div >
        </div>
    )
}

export default Countries;
