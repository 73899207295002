import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { BsFillEyeFill, BsX } from "react-icons/bs";
import TextInput from "../../../components/InputComponents/TextInput";
import {
  successToast,
  errorToast,
} from "../../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../../api/api";
import { PrimaryContext } from "../../../components/MainContext/MainContext";
import terms from "../../../termss.pdf";

const Register = ({ setToggleRegistration }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [regCredentials, setRegCredentials] = useState({});
  const [type, setType] = useState("person");
  const { setToken, setUser, setIsAuthorized } = useContext(PrimaryContext);
  const [agree, setAgree] = useState(false);
  const [fillAll, setFillAll] = useState(false);
  const [allFilled, setAllFilled] = useState(false);
  const [confirmationCodeOk, setConfirmationCodeOk] = useState(false);
  const [popupConfirmationCode, setPopupConfirmationCode] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodePassed, setPromoCodePassed] = useState(false);
  const [promoCodeId, setPromoCodeId] = useState(null);

  const handleType = (e) => {
    setRegCredentials({});
    setAgree(false);
    setFillAll(false);
    setType(e.target.value);
  };

  const handleCredentials = (value, inputName) => {
    setRegCredentials((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleSendEmail = () => {
    axiosClient
      .get(`/sendVerify/${regCredentials.email}`)
      .then(() => {
        successToast(t("code_sent"));
      })
      .catch(() => {
        errorToast("email_registerd_or_error");
      });

    setPopupConfirmationCode(true);
  };

  const handleSendCode = () => {
    axiosClient
      .get(`/verify/${regCredentials.email}/${regCredentials.verificationCode}`)
      .then(() => {
        setConfirmationCodeOk(true);
        setPopupConfirmationCode(false);
        successToast(t("successfully"));
      })
      .catch(() => {
        setConfirmationCodeOk(false);
        errorToast(t("invalid_code_or_error"));
      });
  };

  const handleCheckPromoCode = () => {
    axiosClient
      .get(`promokodi/check/${promoCode}`)
      .then(({ data }) => {
        setPromoCodeId(data.id);
        setPromoCodePassed(true);
      })
      .catch((err) => {
        console.error(err);
        setPromoCodePassed(false);
        errorToast(t("promoCodeDoNotExits"));
      });
  };

  useEffect(() => {
    if (type === "person ") {
      regCredentials.fname !== "" &&
      regCredentials.fname !== null &&
      regCredentials.lname !== "" &&
      regCredentials.lname !== null &&
      regCredentials.fname_eng !== "" &&
      regCredentials.fname_eng !== null &&
      regCredentials.lname_eng !== "" &&
      regCredentials.lname_eng !== null &&
      regCredentials.email !== "" &&
      regCredentials.email !== null &&
      regCredentials.phone !== "" &&
      regCredentials.phone !== null &&
      regCredentials.person_id !== "" &&
      regCredentials.person_id !== null &&
      regCredentials.password !== "" &&
      regCredentials.password !== null &&
      regCredentials.password_confirmation !== "" &&
      regCredentials.password_confirmation !== null
        ? setAllFilled(true)
        : setAllFilled(false);
    } else {
      regCredentials.cname !== "" &&
      regCredentials.cname !== null &&
      regCredentials.cname_eng !== "" &&
      regCredentials.cname_eng !== null &&
      regCredentials.email !== "" &&
      regCredentials.email !== null &&
      regCredentials.phone !== "" &&
      regCredentials.phone !== null &&
      regCredentials.company_id !== "" &&
      regCredentials.company_id !== null &&
      regCredentials.password !== "" &&
      regCredentials.password !== null &&
      regCredentials.password_confirmation !== "" &&
      regCredentials.password_confirmation !== null
        ? setAllFilled(true)
        : setAllFilled(false);
    }
  }, [regCredentials]);

  useEffect(() => {
    if (type === "person ") {
      !(
        regCredentials.fname === "" ||
        regCredentials.lname === "" ||
        regCredentials.fname_eng === "" ||
        regCredentials.lname_eng === "" ||
        regCredentials.email === "" ||
        regCredentials.phone === "" ||
        regCredentials.person_id === "" ||
        regCredentials.password === "" ||
        regCredentials.password_confirmation === ""
      ) && setFillAll(true);
    } else {
      !(
        regCredentials.cname === "" ||
        regCredentials.cname_eng === "" ||
        regCredentials.email === "" ||
        regCredentials.phone === "" ||
        regCredentials.company_id === "" ||
        regCredentials.password === "" ||
        regCredentials.password_confirmation === ""
      ) && setFillAll(true);
    }
  }, [regCredentials]);

  const handleLoginApi = async () => {
    if (confirmationCodeOk) {
      if (agree) {
        if (fillAll) {
          if (
            regCredentials.password === regCredentials.password_confirmation
          ) {
            try {
              const res = await axiosClient.post(`/signup`, {
                ...regCredentials,
                ...(promoCodePassed && { promo_id: promoCodeId }),
              });
              if (res) {
                setToken(res.data.token);
                setUser(res.data.user);
                setIsAuthorized(true);
                successToast(t("successfully"));
                navigate("/registration-success");
              }
            } catch (error) {
              errorToast(t("email_or_already_registerd"));
            }
          } else {
            errorToast(t("passwords_dont_match"));
          }
        } else {
          errorToast(t("fill_all_fields"));
        }
      } else {
        errorToast(t("agree_terms"));
      }
    } else {
      errorToast(t("email_valid_procc"));
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-80 flex items-center justify-center z-50 md:p-0
    sm"
    >
      <div
        className="w-6/12 rounded-[30px] p-10 flex gap-2 flex-col items-start justify-start bg-white
            border-2 border-[#F0F0F0] shadow-sm relative md:w-full md:p-3 md:overflow-auto md:h-full
            md:rounded-none md:border-none"
      >
        <div className="flex justify-end w-full">
          <BsX
            className="cursor-pointer text-2xl text-red-500 md:right-5"
            onClick={() => {
              setToggleRegistration((prev) => !prev);
            }}
          />
        </div>
        <div className="w-full flex items-center justify-between sm:flex-col gap-2 sm:justify-start sm:items-start">
          <h6 className="text-2xl md:text-xl font-bold">{t("sign_up")}</h6>
          <div className="flex items-center gap-2">
            <div className="flex gap-1 items-center cursor-pointer">
              <input
                type="radio"
                id="person"
                name="choice"
                value={"person"}
                onChange={(e) => {
                  handleType(e);
                }}
                checked={type === "person"}
              />
              <label htmlFor="person" className="text-sm font-bold">
                {t("physical_person")}
              </label>
            </div>
            <div className="flex gap-1 items-center cursor-pointer">
              <input
                type="radio"
                id="company"
                name="choice"
                value={"company"}
                onChange={(e) => {
                  handleType(e);
                }}
                checked={type === "company"}
              />
              <label htmlFor="company" className="text-sm font-bold">
                {t("legal_entity")}
              </label>
            </div>
          </div>
        </div>
        {type === "person" ? (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-1 mt-2 w-full gap-2">
              <TextInput
                name={t("name") + " " + t("georgian")}
                inputName={"fname"}
                inputSetter={handleCredentials}
                type="text"
                value={regCredentials.fname}
                style={2}
              />
              <TextInput
                name={t("surname") + " " + t("georgian")}
                inputName={"lname"}
                inputSetter={handleCredentials}
                type="text"
                value={regCredentials.lname}
                style={2}
              />
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-1 mt-2 w-full gap-2">
              <TextInput
                name={t("name_eng")}
                inputName={"fname_eng"}
                inputSetter={handleCredentials}
                type="text"
                value={regCredentials.fname_eng}
                style={2}
              />
              <TextInput
                name={t("surname_eng")}
                inputName={"lname_eng"}
                inputSetter={handleCredentials}
                type="text"
                value={regCredentials.lname_eng}
                style={2}
              />
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-1 mt-2 w-full gap-2">
              <TextInput
                name={t("company_name")}
                inputName={"cname"}
                inputSetter={handleCredentials}
                type="text"
                value={regCredentials.cname}
                style={2}
              />
              <TextInput
                name={t("company_name_eng")}
                inputName={"cname_eng"}
                inputSetter={handleCredentials}
                type="text"
                value={regCredentials.cname_eng}
                style={2}
              />
            </div>
          </>
        )}
        <div className="grid grid-cols-1 mt-2 w-full gap-2">
          <div className="flex items-center gap-2">
            <TextInput
              name={t("email_address")}
              inputName={"email"}
              placeholder={"foxExample@gmail.com"}
              inputSetter={handleCredentials}
              type="text"
              value={regCredentials.email}
              style={2}
            />
            <button
              className="rounded-[20px] text-white font-bold bg-teal_600 text-sm px-10 py-3 shrink-0"
              onClick={handleSendEmail}
            >
              Send Code
            </button>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-1 mt-2 w-full gap-2">
          <TextInput
            name={t("phone")}
            inputName={"phone"}
            placeholder={"XXXXXXXXX"}
            inputSetter={handleCredentials}
            type="text"
            pattern={"number"}
            value={regCredentials.phone}
            style={2}
          />
          {type === "person" ? (
            <div className="flex flex-col gap-2 items-start justify-start w-full">
              <TextInput
                name={t("personal_id")}
                inputName={"person_id"}
                inputSetter={handleCredentials}
                value={regCredentials.person_id}
                style={2}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-1 items-start justify-start w-full">
              <TextInput
                name={t("company_id")}
                inputName={"company_id"}
                inputSetter={handleCredentials}
                type="number"
                value={regCredentials.company_id}
                style={2}
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-1 mt-2 w-full gap-2 items-end">
          <TextInput
            name={`${t("password")} ${t("password_eight_character")}`}
            inputName={"password"}
            inputSetter={handleCredentials}
            type="password"
            value={regCredentials.password}
            SuffixIcon={BsFillEyeFill}
            style={2}
          />
          <TextInput
            name={`${t("password")} ${t("password_eight_character")}`}
            inputName={"password_confirmation"}
            inputSetter={handleCredentials}
            type="password"
            value={regCredentials.password_confirmation}
            SuffixIcon={BsFillEyeFill}
            style={2}
          />
        </div>
        <TextInput
          name={t("promoCode")}
          inputName={"promo"}
          inputSetter={(value, key) => {
            setPromoCodeId(null);
            setPromoCodePassed(false);
            setPromoCode(value);
          }}
          style={2}
        />
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            onChange={(e) => {
              handleCredentials(e.target.checked, "online_shop");
            }}
            className="w-4 h-4 shrink-0"
          />
          <p className="text-sm text-left w-auto font-bold">
            {t("onlineShop")}
          </p>
        </div>
        <div className="flex gap-2 items-center w-full">
          <input
            type="checkbox"
            onChange={(e) => {
              setAgree(e.target.checked);
            }}
            className="w-4 h-4 shrink-0"
          />
          <p
            className={`text-sm text-left w-auto ${
              agree ? "text-teal_600" : "text-red-500"
            }`}
          >
            {t("agree_to_our")}{" "}
            <span className="underline underline-offset-8 font-bold">
              {" "}
              <a target="_blank" href={terms}>
                {t("terms_of_use")}
              </a>{" "}
            </span>
            {t("and")}{" "}
            <span className="underline underline-offset-8 font-bold">
              <a target="_blank" href={terms}>
                {t("privacy_policy")}
              </a>
            </span>
          </p>
        </div>
        <div className="flex flex-col items-start gap-5 mt-5 w-full sm:gap-2">
          {promoCode && !promoCodePassed ? (
            <button
              className="rounded-[20px] text-white font-bold bg-teal_600 text-sm px-10 py-3 shrink-0"
              onClick={handleCheckPromoCode}
            >
              {t("checkPromoCode")}
            </button>
          ) : (
            <button
              className="rounded-[20px] text-white font-bold bg-teal_600 text-sm px-10 py-3 shrink-0"
              onClick={handleLoginApi}
            >
              {t("sign_up")}
            </button>
          )}
        </div>
      </div>

      {popupConfirmationCode && (
        <div
          className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50"
        >
          <div
            className="bg-white flex flex-col gap-5 w-[50%] md:w-[90%] p-10
            border-2 border-[#F0F0F0] shadow-sm rounded-[30px] relative"
          >
            <BsX
              onClick={() => {
                setPopupConfirmationCode(false);
              }}
              className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
            />
            <div className="flex items-center gap-2 sm:flex-wrap">
              <TextInput
                name={"verification code"}
                inputName={"verificationCode"}
                inputSetter={handleCredentials}
                type="text"
                style={2}
              />
              <button
                className="rounded-[20px] text-white font-bold bg-teal_600 text-sm px-10 py-3 shrink-0"
                onClick={handleSendCode}
              >
                Send Code
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
