import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";
import PopupCard from "./PopupCard";

const Popups = () => {
  const [addNewToggler, setAddNewToggler] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [newPopup, setNewPopup] = useState({});

  const handleNewPopup = (value, inputName) => {
    setNewPopup((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  const handleFetchData = () => {
    axiosAdmin
      .get(`/popup`)
      .then(({ data }) => {
        setPopupData(data);
      })
      .catch((err) => {});
  };

  const handleAddNewToDb = () => {
    axiosAdmin
      .post(`/popup`, newPopup)
      .then(({ data }) => {
        handleFetchData();
        successToast("მონაცემი წარმატებით დაემატა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  const handleDelete = (id) => {
    axiosAdmin
      .delete(`/popup/${id}`)
      .then(({ data }) => {
        handleFetchData();
        successToast("მონაცემი წარმატებით წაიშალა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full flex flex-col gap-5 pb-10 sm:pb-5">
        <div className="w-full flex items-center justify-end md:gap-2">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="w-3/12 md:w-full rounded-lg p-3 text-white text-sm bg-violet-400"
          >
            ახალი პოპაპის დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900 ">
            <div className="sm:px-0">
              <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                <div className="flex items-center justify-between flex-wrap gap-5">
                  <div className="flex items-center gap-5 sm:gap-3 ">
                    <div>
                      <h2
                        className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                      >
                        ახალი პოპაპის დამატება
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 sm:w-full">
                    <button
                      onClick={handleAddNewToDb}
                      className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                    >
                      შენახვა
                    </button>
                    <button
                      onClick={() => {
                        handleAddNewToggle(false);
                      }}
                      className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                    >
                      X
                    </button>
                  </div>
                </div>
                <div className="pt-5 flex gap-5 flex-col">
                  <TextInput
                    name={"ტექსტი ქართული"}
                    inputName={"ge"}
                    inputSetter={handleNewPopup}
                  />
                  <TextInput
                    name={"ტექსტი ინგლისური"}
                    inputName={"en"}
                    inputSetter={handleNewPopup}
                  />
                  <TextInput
                    name={"ტექსტი რუსული"}
                    inputName={"ru"}
                    inputSetter={handleNewPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1sm:pt-5 gap-2 my-5">
        {popupData.map((item) => {
          return (
            <PopupCard
              key={item.id}
              item={item}
              id={item.id}
              handleDelete={handleDelete}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Popups;
